/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTBToken = /* GraphQL */ `
  query GetTBToken($userId: ID!) {
    getTBToken(userId: $userId) {
      token
      refreshToken
      embedUrl
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      role
      status
      cogUserPoolId
      cogUserName
      tbUserId
      tbEntityType
      createdAt
      updatedAt
      owner
      groupRead
      groupWrite
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByStatus = /* GraphQL */ `
  query UserByStatus(
    $status: UserStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByRole = /* GraphQL */ `
  query UserByRole(
    $cogUserPoolId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByRole(
      cogUserPoolId: $cogUserPoolId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByCognitoUserName = /* GraphQL */ `
  query UserByCognitoUserName(
    $cogUserName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByCognitoUserName(
      cogUserName: $cogUserName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByThingsboardUserId = /* GraphQL */ `
  query UserByThingsboardUserId(
    $tbUserId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByThingsboardUserId(
      tbUserId: $tbUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        role
        status
        cogUserPoolId
        cogUserName
        tbUserId
        tbEntityType
        createdAt
        updatedAt
        owner
        groupRead
        groupWrite
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
