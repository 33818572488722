import './NavigationDrawer.css';
import React, {useState, useEffect, useContext} from "react";
import { Toolbar, Item} from "devextreme-react/toolbar";
import {Drawer} from "devextreme-react/drawer";
import "devextreme/dist/css/dx.light.css";
import AuthContext from "../../../contexts/AuthContext";
import Profile from "../Profile/Profile";
import NavBar from "../NavBar/NavBar";
import List from "devextreme-react/list";
import {navigate} from 'hookrouter';

const setup = {
    openedStateMode: 'shrink',
    revealMode: 'slide',
    position: 'left',
}

function renderLogo() {
    return <div className="Logo"><img src={process.env.PUBLIC_URL+'IoT-Systems-Logo.png'} className="App-logo" alt="logo" /></div>;
}

function NavigationDrawer({isPublic, menuItems, navbarItems, children, signOut}) {
    const userContext = useContext(AuthContext);
    const [opened, setOpened] = useState(false);

    const MenuButtonOptions = {
        icon: 'menu',
        onClick: () => setOpened(!opened)
    };

    useEffect(() => {
        const handleScroll = () => {
            if (document.documentElement.scrollTop > 200) {
                setOpened(false);
            }
        };
        window.addEventListener("scroll", handleScroll );
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const DrawerContent = () => {
        return (
            <div className="NavigationList">
                    <List
                        dataSource={menuItems}
                        hoverStateEnabled={false}
                        activeStateEnabled={false}
                        focusStateEnabled={false}
                        onItemClick={(e) => navigate(e.itemData.path)}
                        />
            </div>
        )
    }

    return (
        <div className={"NavigationDrawer"}>
                <Toolbar className={"Toolbar"}>
                    { (!isPublic) ?
                        <Item location="before"
                              locateInMenu="never"
                          widget="dxButton"
                          options={MenuButtonOptions}/> : null}
                    <Item location="before"
                          locateInMenu="never"
                          render={renderLogo} />
                    <Item location="center"
                          locateInMenu="auto"
                          render={() => <NavBar menuItems={navbarItems} />} />
                    { (!isPublic) ? <Item location="after"
                        locateInMenu="auto"
                        render={() => <Profile firstname={userContext?.userInfoFromDB?.firstName}
                                               lastname={userContext?.userInfoFromDB?.lastName}
                                               role={userContext?.userInfoFromDB?.role} />}
                    /> : null}
                    { (!isPublic) ? <Item
                        location="after"
                          locateInMenu="auto"
                          widget="dxButton"
                          options={{
                              text: 'Abmelden',
                              elementAttr: {
                                class: "SignoutButton"
                              },
                              class: "test",
                              onClick: signOut
                          }} /> : null}
                </Toolbar>
            {isPublic ?
                <>{children}</>
            :
                <Drawer
                    visible={!isPublic}
                    opened={opened}
                    openedStateMode={setup.openedStateMode}
                    position={setup.position}
                    revealMode={setup.revealMode}
                    render={DrawerContent}
                    closeOnOutsideClick={() => setOpened(false)}
                >
                    {children}
                </Drawer>}
        </div>
    )
}

export default NavigationDrawer;