export const NavbarMenuItems = [
    {
        "name": "Home",
        "path": "https://www.iot-systems.at",
        "link": true
    }, {
        "name": "Über uns",
        "path": "https://www.iot-systems.at/#ueber-uns",
        "link": true
    }, {
        "name": "Kontakt",
        "path": "https://www.iot-systems.at/kontakt",
        "link": true
    }];

export const AdminMenuItems = [
    {
        "text": "Benutzer",
        "path": "/users",
        "link": false,
        "icon": 'group'
    }, {
        "text": "Zähler Import",
        "path": "/watermeter-import",
        "link": false,
        "icon": 'fill'
    }, {
        "text": "Bürger Import",
        "path": "/citizen-import",
        "link": false,
        "icon": 'card'
    }, /*{
        "text": "Zählertausch",
        "path": "/device-swap",
        "link": false,
        "icon": 'refresh'
    },*/ {
        "text": "Dashboard",
        "path": "/dashboard",
        "link": false,
        "icon": 'chart'
    }, /*{
        "text": "Verrechnung Export",
        "path": "/billing-export",
        "link": false,
        "icon": 'money'
    }, {
        "text": "Einstellungen",
        "path": "/settings",
        "link": false,
        "icon": 'preferences'
    }, {
        "text": "Hilfe",
        "path": "/help",
        "link": false,
        "icon": 'help'
    }*/];
export const TechnicianMenuItems = [
    {
        "text": "Zählertausch",
        "path": "/device-swap",
        "link": false,
        "icon": 'refresh'
    }];
export const DefaultMenuItems = [
    {
        "text": "Dashboard",
        "path": "/dashboard",
        "link": false,
        "icon": 'chart'
    }];