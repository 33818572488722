import React, {useState, Suspense, lazy, useEffect} from "react";
import './App.css';
import 'devextreme/dist/css/dx.common.css';
//import 'devextreme/dist/css/dx.material.blue.light.css';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import {AuthProvider} from "./contexts/AuthContext";
import {useRoutes, navigate} from 'hookrouter';
import {Amplify, API, Auth, Hub, DataStore} from 'aws-amplify';
//import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';
import AuthenticatorWithUsername from "./components/auth/AuthenticatorWithUsername";
//import {User} from "./models";
import NavigationDrawer from "./components/menu/NavigationDrawer/NavigationDrawer";
import {useTranslation} from "react-i18next";
import FallbackLoader from "./components/FallbackLoader";
import {AdminMenuItems, DefaultMenuItems, NavbarMenuItems, TechnicianMenuItems} from "./menuitems";
import {userByEmail} from "./graphql/queries";

Amplify.configure(awsconfig);

//const Landingpage = lazy(() =>import("./components/pages/Landingpage"));
const UserManagementView = lazy(() =>import("./components/pages/UserManagementView"));
const WatermeterImport = lazy(() =>import("./components/pages/WatermeterImport"));
const CitizenImport = lazy(() =>import("./components/pages/CitizenImport"));
const Dashboard = lazy(() =>import("./components/pages/Dashboard"));

const Routes = {
  "/": () => <UserManagementView/>,
  "/users": () => <UserManagementView/>,
  "/watermeter-import": () => <WatermeterImport/>,
  "/citizen-import": () => <CitizenImport/>,
  "/dashboard": () => <Dashboard/>
};

function App() {
  const routeResult = useRoutes(Routes);
  const [t] = useTranslation();
  const [user, setUser] = useState({auth:null, userInfoFromDB:null});
  const [menuItems, setMenuItems] = useState(null);

    const fetchUserFromDB = async () => {
        try {
            const auth = await Auth.currentUserInfo();
            const email = auth?.attributes?.email;
            if (email) {
                let {data: {UserByEmail: {items: u}}} = await API.graphql({
                    query: userByEmail,
                    variables: {email: email},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                // filter as deleted marked results
                u = u.filter((item) => item._deleted !== true)
                const userInfoFromDB = u?.length === 1 ? u[0] : null;
                setUser({auth, userInfoFromDB});
                // adapt the menu for different user roles
                switch (userInfoFromDB?.role) {
                    case "SuperAdmin":
                    case "Admin":
                        setMenuItems(AdminMenuItems);
                        break;
                    case "Technician":
                        //navigate("/device-swap")
                        setMenuItems(TechnicianMenuItems);
                        break;
                    default:
                        navigate("/dashboard");
                        setMenuItems(DefaultMenuItems);
                        break;
                }
                return userInfoFromDB;
            } else {
                setUser({auth: null, userInfoFromDB: null});
                setMenuItems(null);
            }
        } catch(error) {
            console.error(error);
        }
    }

  useEffect(() => {
      const hubListenerAuth = async (data) => {
          if (data.payload.event === 'signOut') {
              await DataStore.clear();
              await DataStore.stop();
          }
          if (data.payload.event === 'signIn') {
              await DataStore.start();
              fetchUserFromDB();
          }
      };
      Hub.listen('auth', hubListenerAuth);
      fetchUserFromDB(); // check manually the first time because we won't get a Hub event
      return () => Hub.remove('auth', hubListenerAuth);
  }, []);

  return (
      <I18nextProvider i18n={i18n}>
        <AuthProvider value={user}>
          <NavigationDrawer isPublic={!user?.auth} menuItems={menuItems} navbarItems={NavbarMenuItems} signOut={() => {Auth.signOut().then(setUser({auth:null, userInfoFromDB:null}))}}>
            <div className="App">
              <Suspense fallback={
                <FallbackLoader />
              }>
              <AuthenticatorWithUsername loginMechanisms={['username']} hideSignUp={true}>
                  {routeResult || <div className="PageNotFound"><h1>{t('404.title')}</h1><p>{t('404.text')}</p></div>}
              </AuthenticatorWithUsername>
              </Suspense>
            </div>
          </NavigationDrawer>
        </AuthProvider>
      </I18nextProvider>
    );
}

export default App;